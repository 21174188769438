<template>
    <div>
        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard-analytics' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{  $t('label_email_settings') }}</b-breadcrumb-item>
            </div>
        </div>
        <b-col cols="12" md="12">
            <b-card

                    class="mb-0"
            >

                <b-card-header class="d-flex  mt-1 px-2 py-1 align-items-center">

                    <h5 class="mb-0">{{$t('label_email_settings')}}</h5>

                </b-card-header>

                <b-alert class="m-2" variant="info" show>
                    <div class="">{{$t('label_gmail_settings_info')}}</div>
                </b-alert>

                <div class="mx-2 text-right">
                    <b-button class="ml-auto" variant="outline-primary"  @click="$bvModal.show('shortcode-modal')">
                    {{$t('label_pattern_tags_show')}}
                    </b-button>
                </div>
                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >


                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_gmail')"
                            rules="email"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_gmail')"

                        >

                            <b-form-input

                                    v-model="itemData.gmailAddress"
                                    :placeholder="$t('label_gmail')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_gmail_password')"
                            :rules="itemData.gmailAddress? 'required' : ''"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_gmail_password')"
                        >
                            <b-form-input
                                    type="password"
                                    v-model="itemData.gmail_password"
                                    :placeholder="$t('label_gmail_password')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_footer_of_mail')"

                    >
                        <b-form-group

                                :label="$t('label_footer_of_mail')"
                        >
                            <quill-editor

                                    v-model="itemData.email_footer_text"
                                    :options="editorOption"
                            ></quill-editor>

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>




                    <div class="d-flex mt-2 justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ action == 'editing'? $t('label_edit') : $t('label_save') }}
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="button"
                                variant="outline-secondary"
                                v-on:click="$router.go(-1)"
                        >
                            <!--@click="hide"-->
                            {{ $t('label_cancel') }}

                        </b-button>
                    </div>

                </validation-observer>

            </b-card>
        </b-col>
        <short-code-modal  type="mail-user-settings"></short-code-modal>
    </div>
</template>

<script>


    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {
        BCardHeader, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BFormTextarea, BFormCheckbox,  BAlert,
    } from 'bootstrap-vue'
    import  shortCodeModal from './../pages/pattern/modal/shortCodeModal'
    import vSelect from 'vue-select'
    import { quillEditor } from 'vue-quill-editor'
    import Quill from 'quill'
    import htmlEditButton from "quill-html-edit-button";
    Quill.register('modules/htmlEditButton', htmlEditButton);

    export default {
        name: "editItem.vue",
        components: {
            BCardHeader,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton, BCard,
            vSelect,
            BFormCheckbox,
            BFormTextarea,
            BAlert,
            shortCodeModal,
            ValidationProvider,
            ValidationObserver,
            quillEditor

        },
        data() {
            return {
                // https://quilljs.com/docs/modules/toolbar/
                PREFIX: 'email',

                itemData: {},

                editorOption: {
                    theme: 'snow',
                    modules: {
                        htmlEditButton: {
                            okText: this.$t('label_submit'),
                            cancelText: this.$t('label_cancel'),
                            buttonTitle: "HTML",
                            msg: "Edit the content in HTML format",
                            buttonHTML: "&lt;&gt;",
                        },
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'],
                            ['blockquote', 'code-block'],
                            [{'header': 1}, {'header': 2}],
                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                            [{'script': 'sub'}, {'script': 'super'}],
                            [{ 'indent': '-1'}, { 'indent': '+1' }],
                            [{'direction': 'rtl'}],
                            [{'size': ['small', false, 'large', 'huge']}],
                            [{'header': [1, 2, 3, 4, 5, 6, false]}],
                            [{'color': []}, {'background': []}],
                            [{'font': []}],
                            [{'align': []}],
                            ['clean'],
                            ['image', 'video']
]
                    }
                },

                action: 'adding',

                blankItemData: {
                    // emailAddressSender: '',
                    gmailAddress: '',
                    gmail_password: '',
                    email_footer_text: '',
                },


            }
        },
        created() {
            this.initData();
        },
        methods: {
            initData() {

                this.async('get', '/settings/' + this.PREFIX, {}, function (resp) {
                    let editedData = resp.data;
                    let item = Object.assign({}, this.blankItemData);
                    // item.id = user.id;
                    for (let prop in item) {
                        if (editedData.hasOwnProperty(prop)) {
                            item[prop] = editedData[prop];
                        }
                    }
                    this.itemData = item;
                });

            },
            onSubmit() {
                if (this.action == 'adding') {
                    this.async('post', '/settings/' + this.PREFIX, this.itemData, function (resp) {
                        // this.$emit('item-added',{});
                        // this.$bvModal.hide(this.PREFIX + '-modal');
                        // this.$router.push({name: 'secretaries_list'});

                    });
                }

            },

        }
    }
</script>

<style lang="scss"> //!!! for editor
@import '@core/scss/vue/libs/quill.scss';
.ql-editor{
    min-height: 150px;
}
</style>